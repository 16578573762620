import React, { Component } from 'react';
import { Route } from 'react-router-dom';
// import Header from '../Header/Header';
import Nav from '../Nav/Nav';
import Main from '../Main/Main';
import About from '../About/About';
import Products from '../Products/Products';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import './App.css';

class App extends Component {
  componentDidMount() {
    document.addEventListener("touchstart", function(){}, true);
  }

  render() {
    return (
      <div className="App">
        <div id="container">
					<Nav />
					{/* <Header /> */}
          <main>
            <Route exact path="/" component={Main} />
            <Route exact path="/about" component={About} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/contact" component={Contact} />
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
