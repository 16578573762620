import React from 'react';
import Header from '../Header/Header';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import './About.css';

export default function About() {
  return (
    <section id="about-section">
			<ScrollToTopOnMount />
			<Header />
      <p id="about-text">
        If you’re looking for high quality and personal service, you’ve come to the right place. At
        Platina Design, Inc., we’ll give you the attention and personal service you’ll come to
        expect and enjoy. We offer custom designs with high quality craftsmanship. Specializing in
        loose diamonds, platinum design, pearls, and other fine jewelry. Platina Design, Inc. has
        experience to work for you with certified GIA gemologists.
      </p>
    </section>
  );
}
