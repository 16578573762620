import React from 'react';

export default function ImageModal(props) {
  return (
    <div className="modal-frame">
      <div
        className="close-button"
        onClick={e => {
          props.handleClose(e);
        }}
      >
        <p className="close-button-text invisible-text"><span className="italic">close</span></p>
        <span className="close-button-symbol">&times;</span>
      </div>
      <img
        loading="lazy"
				className="enlarged-image"
        src={props.clickedImageSrc}
        alt={props.clickedImageName}
      />
      <div className="caption">
        {props.clickedImageName}
      </div>
    </div>
  );
}
