import React, { Component } from 'react';
import Header from '../Header/Header';
import HomepageProducts from '../HomepageProducts/HomepageProducts';
import ImageModal from '../ImageModal/ImageModal';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import igLogo from '../../img/icons/ig.png';
import fbLogo from '../../img/icons/fb.png';
import map from '../../img/map.PNG';
import './Main.css';

export default class Main extends Component {
  state = {
    showModal: false,
    clickedMapSrc: ''
  };

  handleMapClick = target => {
    this.setState({ showModal: true, clickedMapSrc: target.src });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <section id="home-section">
				<ScrollToTopOnMount />
        <div className="home-top-container">
					<div id="main-content">
						<div className="hours-info">
							<h2>Open: Monday - Saturday,</h2>
							<h2 className="italic">9:30 AM - 5:00 PM</h2>
						</div>
						<div className="ig-info social-media-link">
							<img loading="lazy" src={igLogo} className="ig-icon image-icon" alt="Icon of Instagram logo" />
							<a
								href="https://www.instagram.com/platina_design/"
								className="ig-link no-underline"
							>
								platina_design
							</a>
						</div>
						<div className="fb-info social-media-link">
							<img loading="lazy" src={fbLogo} className="fb-icon image-icon" alt="Icon of Facebook logo" />
							<a
								href="https://www.facebook.com/Platina-Design-Inc-1132192050251376/"
								className="fb-link"
							>
								Visit on Facebook
							</a>
						</div>
					</div>
					<div className="home-top-right-container">
						<Header />
						<div className="homepage-address">
							<div className="contact-numbers">
								<p className="contact-number">
									<span className="bold">Tel: </span>
									<a href="tel:+1-213-623-6456" className="no-underline">
										(213) 623-6456
									</a>
								</p>
								<p className="contact-number">
									<span className="bold">Fax:</span> (213) 623-6419
								</p>
							</div>
							<h4 id="location-header">Location:</h4>
							<a
								href="https://www.google.com/maps/place/Platina+Design+Inc/@34.0459407,-118.256137,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c7b53fdbe63b:0x5ad9d49315e7c8fc!8m2!3d34.0459407!4d-118.2539483"
								className="no-underline"
							>
								<p>650 S. Hill St., Booth #B6</p>
								<p>Los Angeles, CA, 90014</p>
							</a>
							<img
								loading="lazy"
								src={map}
								id="location-map"
								alt="Platina Design Inc on a map of Los Angeles, CA"
								onClick={e => this.handleMapClick(e.target)}
							/>
							<p id="map-footnote">
								<span className="small-text">(Click to enlarge)</span>
							</p>
							{this.state.showModal && (
								<ImageModal
									clickedImageSrc={this.state.clickedMapSrc}
									clickedImageName={'Map of Downtown, Los Angeles, CA'}
									handleClose={() => {
										this.handleClose();
									}}
								/>
							)}
						</div>
					</div>
				</div>
				<HomepageProducts />
        <address id="homepage-address">
					<div id="hours-info">
						<h2>Open: Monday - Saturday,</h2>
						<h2 className="italic">9:30 AM - 5:00 PM</h2>
					</div>
					<div className="contact-numbers">
            <p className="contact-number">
              <span className="bold">Tel: </span>
              <a href="tel:+1-213-623-6456" className="no-underline">
                (213) 623-6456
              </a>
            </p>
            <p className="contact-number">
              <span className="bold">Fax:</span> (213) 623-6419
            </p>
          </div>
					<h4 id="location-header">Location:</h4>
          <a
            href="https://www.google.com/maps/place/Platina+Design+Inc/@34.0459407,-118.256137,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c7b53fdbe63b:0x5ad9d49315e7c8fc!8m2!3d34.0459407!4d-118.2539483"
            className="no-underline"
          >
            <p>650 S. Hill St., Booth #B6</p>
            <p>Los Angeles, CA, 90014</p>
          </a>
					<img
            loading="lazy"
						src={map}
            id="location-map"
            alt="Platina Design Inc on a map of Los Angeles, CA"
            onClick={e => this.handleMapClick(e.target)}
          />
          <p className="map-footnote">
            <span className="small-text">(Click to enlarge)</span>
          </p>
          {this.state.showModal && (
            <ImageModal
              clickedImageSrc={this.state.clickedMapSrc}
              clickedImageName={'Map of Downtown, Los Angeles, CA'}
              handleClose={() => {
                this.handleClose();
              }}
            />
          )}
					<div className="ig-info social-media-link">
						<img loading="lazy" src={igLogo} className="ig-icon image-icon" alt="Icon of Instagram logo" />
						<a
							href="https://www.instagram.com/platina_design/"
							className="ig-link no-underline"
						>
							platina_design
						</a>
					</div>
					<div className="fb-info social-media-link">
						<img loading="lazy" src={fbLogo} className="fb-icon image-icon" alt="Icon of Facebook logo" />
						<a
							href="https://www.facebook.com/Platina-Design-Inc-1132192050251376/"
							className="fb-link"
						>
							Visit on Facebook
						</a>
					</div>
        </address>
      </section>
    );
  }
}
