import React, { Component } from 'react';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import Header from '../Header/Header';
import ImageModal from '../ImageModal/ImageModal';
import map from '../../img/map.PNG';
import './Contact.css';

export default class Contact extends Component {
  state = {
    showModal: false,
    clickedMapSrc: ''
  };

  handleMapClick = target => {
    this.setState({ showModal: true, clickedMapSrc: target.src });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <section id="contact-section">
				<ScrollToTopOnMount />
				<Header />
        <div id="contact-description">
          <p className="contact-text">
            For <span className="italic">questions</span> about products, custom orders,
          </p>
          <p className="contact-text">
            appraisals, and other inquiries, please <span className="italic">call</span> or{' '}
            <span className="italic">visit</span>.
          </p>
        </div>
        <div>
          <address id="contact-info">
            <p id="contact-number">
              <span className="bold">Tel:</span>{' '}
              <a href="tel:+1-213-623-6456" className="no-underline">
                (213) 623-6456
              </a>
            </p>
            <p id="contact-fax">
              <span className="bold">Fax:</span> (213) 623-6419
            </p>
            <h2 id="contact-location-header">Location:</h2>
            <a
              href="https://www.google.com/maps/place/Platina+Design+Inc/@34.0459407,-118.256137,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c7b53fdbe63b:0x5ad9d49315e7c8fc!8m2!3d34.0459407!4d-118.2539483"
              className="no-underline"
            >
              <p className="location-address">650 S. Hill St., Booth #B6</p>
              <p className="location-address">Los Angeles, CA, 90014</p>
            </a>
            <img
              loading="lazy"
							src={map}
              id="location-map"
              alt="Platina Design Inc on a map of Los Angeles, CA"
              onClick={e => this.handleMapClick(e.target)}
            />
            <p id="map-footnote">
              <span className="small-text">(Click to enlarge)</span>
            </p>
            {this.state.showModal && (
              <ImageModal
                clickedImageSrc={this.state.clickedMapSrc}
                clickedImageName={'Map of Downtown, Los Angeles, CA'}
                handleClose={() => {
                  this.handleClose();
                }}
              />
            )}
            <h2 id="contact-hours">Hours:</h2>
            <p id="hours-open">Open: Mon. - Sat., 9:00 AM - 5:00 PM</p>
            <p id="contact-email">
              <span className="bold">Email:</span>{' '}
              <a href="mailto:platinainc3201@att.net">platinainc3201@att.net</a>
            </p>
          </address>
        </div>
      </section>
    );
  }
}
