import React from 'react';
import phone from '../../img/icons/phone.png';
import email from '../../img/icons/email.png';
import facebookGrey from '../../img/icons/facebook-grey.png';
import instagramGrey from '../../img/icons/instagram-grey.png';
import pin from '../../img/icons/pin-icon.png';
import './Footer.css'

export default function Footer() {
  return (
    <footer>
      <h5 id="copyright-text">&#169; 2023 Platina Design, Inc.</h5>
      <p id="credit-text">
				<span className="small-text">All rights reserved.</span>
        <span className="small-text">This website was created by cpWebDeveloper99@gmail.com</span>
      </p>
			<div className="footer-spacer"></div>
			<div className="mobile-footer-nav flex justify-between">
				<a
					href="tel:+1-213-623-6456"
					className="footer-nav-button"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="call +12136236456"
				>
					<img loading="lazy" className="footer-nav-icon" src={phone} alt="phone icon" />
				</a>
				<a
					href="mailto:platinainc3201@att.net"
					className="footer-nav-button"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="email platinainc3201@att.net"
				>
					<img
						loading="lazy"
						className="footer-nav-icon footer-email-icon"
						src={email}
						alt="email icon"
					/>
				</a>
				<a
					href="https://www.instagram.com/platina_design/"
					className="footer-nav-button"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="open Instagram page"
				>
					<img
						loading="lazy"
						className="footer-nav-icon"
						src={instagramGrey}
						alt="Instagram icon"
					/>
				</a>
				<a
					href="https://www.facebook.com/Platina-Design-Inc-1132192050251376/"
					className="footer-nav-button"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="open Facebook page"
				>
					<img
						loading="lazy"
						className="footer-nav-icon"
						src={facebookGrey}
						alt="Facebook icon"
					/>
				</a>
				<a
					href="https://www.google.com/maps/place/Platina+Design+Inc/@34.0459407,-118.256137,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c7b53fdbe63b:0x5ad9d49315e7c8fc!8m2!3d34.0459407!4d-118.2539483"
					className="footer-nav-button"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="open Google Maps address"
				>
					<img
						loading="lazy"
						className="footer-nav-icon ping-icon"
						src={pin}
						alt="Map icon"
					/>
				</a>
			</div>
    </footer>
  );
}
