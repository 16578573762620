import React, { Component } from 'react';
import Pagination from '../Pagination/Pagination';
// import igLogo from '../../img/icons/ig.png';
import './HomepageProducts.css';
import ImageModal from '../ImageModal/ImageModal';

export default class Products extends Component {
  constructor() {
    super();
    let imageNames = [...Array(290).keys()].map(i => ({
      id: i + 1,
      name: 'picture-(' + (i + 1) + ').JPG'
    }));
    this.state = {
      exampleItems: imageNames,
      pageOfItems: [],
      showModal: false,
      clickedImageSrc: '',
      clickedImageName: ''
    };

    this.onChangePage = this.onChangePage.bind(this);
  }

  importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  }

  handleClick = target => {
    this.setState({
      showModal: true,
      clickedImageSrc: target.src,
      clickedImageName: target.id.slice(9, -5)
    });
  };

  handleClose = e => {
    this.setState({ showModal: false });
  };

  onChangePage(pageOfItems) {
    // update state with new page of items
    this.setState({ pageOfItems: pageOfItems });
  }

  findSource(item) {
    return '../../img/products/' + item.name;
  }

  render() {
    const images = this.importAll(
      require.context('../../img/products', false, /\.(png|jpe?g|JPG|svg)$/)
    );
    return (
      <section id="homepage-products-section">
        {/* <div id="product-info">
          <p className="product-info-text">
            We offer <span className="italic custom-font">custom design</span> with high quality
            craftsmanship, and all of our work is 100% guaranteed.
          </p>
          <p className="product-info-text">
            We specialize in <span className="italic gold-font">platinum</span>,{' '}
            <span className="italic gold-font">14 karat gold</span>, and{' '}
            <span className="italic gold-font">18 karat gold</span>.
          </p>
          <p className="product-info-text">
            Large selection of loose <span className="italic custom-font">diamonds</span>, loose{' '}
            <span className="italic custom-font">pearls</span>, and{' '}
            <span className="italic custom-font">colored stones</span> available.
          </p>
        </div> */}
        {/* <div className="ig-info-small social-media-link">
          <img loading="lazy" src={igLogo} className="ig-icon-small image-icon" alt="Icon of Instagram logo" />
          <a
            id="gallery-top"
            href="https://www.instagram.com/platina_design/"
            className="ig-link no-underline"
          >
            platina_design
          </a>
        </div> */}
        <div className="product-details">
          <p className="italic bold">
            *All images belong to Platina Design, Inc., and require permission for use
          </p>
        </div>
        <div className="image-gallery-frame">
          {this.state.pageOfItems.map(item => (
            <div className="product-image-frame" key={item.id}>
              <img
                loading="lazy"
								id={item.name}
                className="product-image"
                src={images[item.name]}
                alt={'Custom jewelry design thumbnail -' + item.name}
                onClick={e => {
                  this.handleClick(e.target);
                }}
              />
            </div>
          ))}
          <Pagination items={this.state.exampleItems} onChangePage={this.onChangePage} />
          <p className="bold">(click thumbnail to enlarge)</p>
        </div>
        {this.state.showModal && (
          <ImageModal
            clickedImageSrc={this.state.clickedImageSrc}
            clickedImageName={
              'Custom jewelry design: (Catalogue #00' + this.state.clickedImageName + ')'
            }
            handleClose={() => {
              this.handleClose();
            }}
          />
        )}
				<div className="product-info">
          <p className="product-info-text">
            We offer <span className="italic custom-font">custom design</span> with high quality
            craftsmanship, and all of our work is 100% guaranteed.
          </p>
          <p className="product-info-text">
            We specialize in <span className="italic gold-font">platinum</span>,{' '}
            <span className="italic gold-font">14 karat gold</span>, and{' '}
            <span className="italic gold-font">18 karat gold</span>.
          </p>
          <p className="product-info-text">
            Large selection of loose <span className="italic custom-font">diamonds</span>, loose{' '}
            <span className="italic custom-font">pearls</span>, and{' '}
            <span className="italic custom-font">colored stones</span> available.
          </p>
        </div>
      </section>
    );
  }
}
