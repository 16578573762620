import React from 'react';
import { NavLink } from 'react-router-dom';
import platinaIcon from '../../img/icons/platina-design.png';
import './Nav.css';

export default function Nav() {
  return (
    <nav>
      <div id="nav-bar">
				<NavLink id="nav-logo-link" className="no-underline" to="/">
					<div className="platina-design-logo">
						<img loading="lazy" src={platinaIcon} className="platina-design-logo-icon" alt="Platina Design Inc icon" />
						{/* <h1 className="main-nav-logo">Platina Design, Inc.</h1> */}
					</div>
				</NavLink>
        <ul id="nav-list">
          <li className="nav-list-item">
            <NavLink className="nav-link" to={'/'}>
              Home
            </NavLink>
          </li>
          <li className="nav-list-item">
            <NavLink className="nav-link" to={'/about'}>
              About
            </NavLink>
          </li>
          <li className="nav-list-item">
            <NavLink className="nav-link" to={'/products'}>
              Products
            </NavLink>
          </li>
          <li className="nav-list-item">
            <NavLink className="nav-link" to={'/contact'}>
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
